const additionalObjects = {
	icosidodeca: function(p) {
		var obj = { points: [], faces: [] };
		var otmp = this.icosa(p);
		for (var i = 0; 10 > i; i++) {
			var s = (i & 1) ? [11, (i + 2) % 10, (i + 1) % 10] : [10, (i + 2) % 10, (i + 1) % 10];
			for (var j = 0; 3 > j; j++) {
				obj.points.push(otmp.points[i].add(otmp.points[s[j]]));
			}
		}
		for (var i = 0; 12 > i; i++) {
			var k = i & 1;
			var j = i * 3;
			obj.faces.push((10 > i)
				? [j, (j + 25 - 24 * k) % 30,
					(j + 29 - 27 * k) % 30, (j + 2 + 27 * k) % 30, (j + 1 + 24 * k) % 30]
				: [3 * k, 6 + 21 * k, 12 + 9 * k, 18 - 3 * k, 24 - 15 * k]);
		}
		for (var i = 0; 20 > i; i++) {
			var j = (i & 1) * 3;
			var s = (10 > i) ? [1, 2 + j, 5 - j] : [1, 6 - j * 2, j * 2];
			obj.faces.push(
				[((i % 10) * 3 + s[0]) % 30, ((i % 10) * 3 + s[1]) % 30, ((i % 10) * 3 + s[2]) % 30]);
		}
		return obj;
	}
};
